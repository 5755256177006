.modal {
  padding: 24px 8px;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border: 1px solid #dce7fb;
  border-radius: 8px;

  .text {
    font-size: 24px;
    line-height: 32px;
    color: #232B39;
  }

  .footer {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > button {
      padding: 8px 24px;
    }
  }
}