.spinner {
  color: var(--chakra-colors-darkPurple);
}

.spinnerContainer{
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1;
}
