.input {
  &::placeholder {
    font-size: 15px;
    line-height: 20px;
    color: #8592A3;
  }
}

.crossIcon {
  padding: 4px;
  border-radius: 50%;
  color: var(--chakra-colors-darkGrey);
  transition: ease-out 0.2s;
  cursor: pointer;

  &:hover {
    background: var(--chakra-colors-defaultGrey);
    color: var(--chakra-colors-mainPurple);
  }
}