.formBox {
  gap: 12px;
  flex-direction: column;
  width: 100%;

  .label {
    font-size: 15px;
    line-height: 20px;
    color: #232B39;
  }

  .error {
    font-size: 15px;
    color: var(--chakra-colors-mainRed);
  }
}