.form {
  width: 100%;
  .title {
    font-size: 24px;
    line-height: 32px;
  }

  .dragAndDrop {
    width: 550px;
  }
}