.header {
  position: absolute;
  padding: 0 32px 0 16px;
  width: 100%;
  height: 60px;
  align-items: center;
  background-color: #eff5fe;
  justify-content: space-between;
  z-index: 100;
}
