.variant {
  font-size: 15px;
  line-height: 20px;
  color: #BCC3CD;

  &__active {
    color: #232B39;
  }
}

.dragAndDrop {
  height: 238px;
  max-width: 550px;
}