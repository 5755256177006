input[type="file"] {
  display: none;
}

.fileUploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed;
  border-radius: 8px;
  min-height: 200px;
  height: inherit;
  padding: 20px;

  .fileIn {
    position: relative;

    .closeButton {
      position: absolute;
      right: -16px;
      top: -16px;
    }
  }
}


.fileUploadButton {
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 8px;
  font-weight: 400;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: 36px;
  min-width: var(--chakra-sizes-8);
  font-size: 15px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  background-color: var(--chakra-colors-mainPurple);
  color: var(--chakra-colors-white);
  margin-left: 16px;
}

.dragFileElement {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  z-index: 1000;
}